/* CSS to hide increment and decrement buttons for number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
}

::placeholder {
    color: red;
    padding: 20px;
}

.text-area-container {
    position: relative;
    color: white;
}

.forms {
    position: relative;
    color: white;
    height: 2rem;
}

.forms__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border: 2px solid #e1e5ee; */
    border: 2px solid black;
    color: black;
    outline: none;
    background: none;
    padding: 0.5rem 0;
    transition: all 160ms ease-in;
    font-family: 'Arcon', sans-serif;
    font-size: 18px;
    text-indent: 0.3rem;
}

.forms__input:hover {
    border-color: rgb(211, 174, 95);
}

.forms__input:hover + .forms__text {
    border-color: rgb(211, 174, 95);
}

.forms__input:focus {
    border-color: rgb(211, 174, 95);
}

.forms__input:focus + .forms__text {
    border-color: rgb(211, 174, 95);
}

.forms__label {
    position: absolute;
    left: 1rem;
    top: -0.5rem;
    padding: 0 0.5rem;
    color: #000;
    cursor: text;
    background-color: #F8F8FF;
}

.forms__longLabel {
    position: absolute;
    left: 1rem;
    top: -0.5rem;
    padding: 0 0.5rem;
    color: #000;
    cursor: text;
    background-color: #F8F8FF;
}

.forms__text {
    position: absolute;
    background-color: #F8F8FF;
    height: 48px;
    width: 47px;
    right: -2px;
    top: 2px;
    border-left: 2px solid black;
    transition: all 160ms ease-in;
}

.forms__text p {
    position: absolute;
    bottom: -4px;
    left: 10px;
    color: black;
}

.forms__textarea {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 150px;
    max-height: 500px;
    /* border: 2px solid #e1e5ee; */
    border: 2px solid black;
    color: black;
    outline: none;
    background: none;
    padding: 0.5rem 0;
    font-family: 'Arcon', sans-serif;
    resize: vertical;
    font-size: 18px;
    margin-bottom: -20px;
}

.forms__textarea:hover {
    border-color: rgb(211, 174, 95);
}

.forms__textarea:focus {
    border-color: rgb(211, 174, 95);
}

.forms__label__textarea {
    position: absolute;
    left: 1rem;
    top: -0.5rem;
    padding: 0 0.5rem;
    color: #000;
    cursor: text;
    /* background-color: #0f1a25; */
    background-color: #F8F8FF;
}
 
.select-container {
    position: relative;
    width: 100%;
    color: white;
    margin: 0 2rem 2rem 0;
}

.forms__select {
    width: 100%;
    border: 2px solid #e1e5ee;
    color: white;
    outline: none;
    background: none;
    padding: .5rem .5rem;
    font-family: 'Arcon', sans-serif;
    font-size: 18px;
    appearance: none;
}

.forms__select__option {
    background-color: transparent !important;
    border-radius: 0;
}

.select-container .forms__select__icon {
    position: absolute;
    top: 0.8rem;
    right: 0;
    padding-right: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: text;
    background-color: transparent;
    z-index: -1;
}

.forms__label__select {
    position: absolute;
    left: 1rem;
    top: -0.5rem;
    padding: 0 0.5rem;
    color: #fff;
    cursor: text;
    background-color: #0f1a25;
}


.input-checkbox {
    width: 20px !important;
    height: 20px !important;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 4px;
}

.input-checkbox:hover {
    width: 20px;
    height: 20px;
    background-color: red;
    cursor: pointer;
    background-color: rgb(211, 174, 95);
}

.input-checkbox-clicked {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-color: rgb(211, 174, 95);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 470px) {
    .forms__longLabel {
        position: absolute;
        left: 0.4rem;
        top: -0.5rem;
        padding: 0 0.3rem;
        color: #fff;
        cursor: text;
        background-color: #0f1a25;
        font-size: 12px;
    }
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
}

.modal-container {
    /* background: #0f1a25; */
    background-color: #F8F8FF;
    padding: 2rem;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
    width: 35rem;
    margin: 0 1rem 0 1rem;
    max-height: 90%;
    overflow-y: auto;
    border-radius: 6px;
}

.modal-header {
    border-bottom: 1px solid black;
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.modal-header__monthly {
    border-bottom: 1px solid black;
    margin-bottom: 30px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.modal-header__monthly_info {
    text-align: end;
    width: 100%;
}

@media (max-width: 768px) {
    .modal-header__monthly {
        border-bottom: 0;
        flex-direction: column;
        text-align: start;
    }
    .modal-header__monthly_info {
        text-align: start;
        width: 100%;
    }
    .modal-header__monthly_info p {
        margin: 0;
    }
    .modal-header {
        font-size: 14px;
        gap: 1rem;
    }
    .modal-container {
        background: #0f1a25;
        padding: 0.8rem;
        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
        width: 35rem;
        margin: 0.5rem;
        max-height: 90%;
        overflow-y: auto;
        border-radius: 6px;
    }
}

.modal-header svg:hover {
    color: rgb(211, 174, 95);
    cursor: pointer;
}

.modal-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
}

.modal-input > div {
    width: 100%;
}

@media (max-width: 500px) {
    .modal-input {
        display: flex;
        flex-direction: column;
    }
}

.modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3rem;
}

.modal-body-after {
    display: flex;
    flex-direction: column;
}

.modal-body-after > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.modal-body-after > div > div {
    height: 8px;
    width: 8px;
    background-color: rgb(211, 174, 95);
    border-radius: 50%;
}

.modal-footer {
    padding-top: 30px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}

.modal-btn {
    background-color: transparent;
    border: 1px solid black;
    color: black;
    padding: 0.5rem;
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.4s;
}

.modal-btn:hover {
    background-color: rgb(0, 0, 0, 0.3);
    border-color: rgb(0, 0, 0, 0.01);
}

.cookie-modal-container {
    position: fixed;
    bottom: 1%;
    width: 100%;
    max-width: 1920px;
}

.cookie-modal {
    height: 200px;
    width: 100%;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
}

.inventory-wrapper {
    margin: 0 2rem 5rem 2rem;
}

.slider-wrapper {
    overflow: hidden;
    white-space: nowrap;
    height: 430px; /* TEMPORÄR LÖSNING */
}

.slider-header {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.slider-header h1 {
    margin: 0;
}

.slider-wrapper:hover .slider-container {
    animation-play-state: paused;
}

.slider-container {
    display: inline-flex;
    flex-direction: row;
    animation: 200s slide infinite linear;
    gap: 1rem;
}

.slides {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.slides img {
    width: 400px;
    height: 300px;
    object-fit: cover;
}

.slides-slick {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.slides-slick .image-container {
    position: relative;
    width: 100%;
    padding-bottom: 75%; /* You can adjust this percentage to achieve the desired aspect ratio */
    overflow: hidden;
}

.slides-slick img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@media (max-width: 768px) {
    .slider-header h1 {
        font-size: 24px;
    }
    .inventory-wrapper {
        margin: 0 1rem 5rem 1rem;
    }
}
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    height: 100vh;
    width: 100vw;
    opacity: 1;
    z-index: 999;
    /* background-color: #0f1a25; */
    background-color: #F8F8FF;
}

.test {
    position: absolute;
    z-index: 1000;
    display: flex;
    justify-content: center;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
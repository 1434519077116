button.learn-more-1 {
    --bg: #f3f8fa;
    --white: #fff;
    --black: #000;
    --transition-duration: 0.45s;
    --transition-ease: cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
  }
  
  button.learn-more-1 {
    width: 17rem;
    height: auto;
  }
  
  button.learn-more-1 .circle {
    transition: all var(--transition-duration) var(--transition-ease);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    border: 1px solid white;
    border-width: 200%;
    background: var(--black);
    border-radius: 1.625rem;
  }
  
  button.learn-more-1 .circle .icon {
    transition: all var(--transition-duration) var(--transition-ease);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: var(--white);
  }
  
  button.learn-more-1 .circle .icon.arrow {
    transition: all var(--transition-duration) var(--transition-ease);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
  }
  
  button.learn-more-1 .circle .icon.arrow::before {
    position: absolute;
    content: '';
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid var(--white);
    border-right: 0.125rem solid var(--white);
    transform: rotate(45deg);
  }
  
  button.learn-more-1 .button-text {
    transition: all var(--transition-duration) var(--transition-ease);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0 0 2rem;
    margin: 0 0 0 1.85rem;
    color: var(--white);
    font-weight: 300;
    font-size: 36px;
    line-height: 0.7;
    text-align: start;
  }
  
  button.learn-more-1:hover .circle {
    width: 100%;
  }
  
  button.learn-more-1:hover .circle .icon.arrow {
    background: var(--white);
    transform: translate(1rem, 0);
  }
  
  button.learn-more-1:hover .button-text {
    color: var(--white);
  }


/* @media (max-width: 768px) {
  button.learn-more-1 {
    display: none;
  }
} */
button.learn-more {
  --bg: #f3f8fa;
  --white: #ffffff;
  --black: #000;
  --transition-duration: 0.45s;
  --transition-ease: cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
}

button.learn-more {
  width: 15rem;
  height: auto;
}

button.learn-more .circle {
  transition: all var(--transition-duration) var(--transition-ease);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  border: 1px solid white;
  border-width: 200%;
  background: var(--black);
  border-radius: 1.625rem;
}

button.learn-more .circle .icon {
  transition: all var(--transition-duration) var(--transition-ease);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: var(--white);
}

button.learn-more .circle .icon.arrow {
  transition: all var(--transition-duration) var(--transition-ease);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid var(--white);
  border-right: 0.125rem solid var(--white);
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all var(--transition-duration) var(--transition-ease);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0 0 2rem;
  margin: 0 0 0 1.85rem;
  color: black;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
  text-align: start;
}

button.learn-more:hover .circle {
  width: 100%;
}

button.learn-more:hover .circle .icon.arrow {
  background: var(--white);
  transform: translate(1rem, 0);
}

button.learn-more:hover .button-text {
  color: var(--white);
}
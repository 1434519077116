/* Track */
::-webkit-scrollbar {
    width: 12px;
    background-color: rgb(58, 58, 58);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Hide the Picture-in-Picture button */
video::-webkit-media-controls-enclosure {
    display: none !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Arcon', sans-serif;
    /* background-color: #0f1a25;
    color: white; */
    background-color: #f8f8ff;
    color: black;
}

@font-face {
    font-family: 'Arcon';
    src: url('../src/utils/Arcon-Regular_1.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

li {
    text-decoration: none;
}

a {
    text-decoration: none;
    color: black;
    /* color: white; */
}

p {
    font-size: 18px;
}

img {
    user-select: none;
    -webkit-user-drag: none;
}

.main-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1920px;
    margin: 0 auto;
}

.outlet-wrapper {
    flex: 1;
}

.btn {
    border: 3px solid white;
    cursor: pointer;
    color: white;
    background-color: transparent;
    width: 9rem;
    height: 3rem;
    font-size: large;
    border-radius: 25px;
    transition: 0.4s;
}

.btn:hover {
    background-color: rgb(0, 0, 0, 0.3);
    border-color: rgb(0, 0, 0, 0.01);
}

.btn-secondary {
    border: 3px solid white;
    cursor: pointer;
    color: white;
    background-color: rgb(0, 0, 0, 0.7);
    width: 9rem;
    height: 3rem;
    font-size: large;
    border-radius: 25px;
    transition: 0.4s;
}

.btn-secondary:hover {
    background-color: rgb(0, 0, 0, 0.3);
    border-color: rgb(0, 0, 0, 0.01);
}

.btn-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .btn-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .logo-img {
        max-width: 100%;
        min-width: 250px;
    }
}

.logo-img {
    width: 70%;
    height: auto;
}

.ka-new {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
    height: 700px;
    overflow: hidden;
    margin-bottom: 8rem;
}

.ka-new img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ka-container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    text-align: left;
    margin: 0 0 0 5rem;
    font-size: x-large;
}

.ka-container > h1 {
    font-size: 3rem !important;
    margin: 0;
}

.ka-container > p {
    font-size: 1.5rem !important;
    max-width: 700px;
    background-color: rgb(0, 0, 0, 0.5);
    padding: 0.3rem;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .ka-container {
        margin: 0.5rem;
    }
    .ka-container h1 {
        margin: 0;
    }
    .ka-new {
        height: 250px;
    }
    .ka-container > p {
        font-size: 1rem !important;
        max-width: 700px;
        background-color: rgb(0, 0, 0, 0.5);
        padding: 0.3rem;
        border-radius: 4px;
    }
}

.disable-background-scroll {
    overflow: hidden;
}

.contact-container {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: start;
    gap: 2rem;
    color: white;
    width: 100%;
}

.contact-text-container {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    padding: 4rem 2rem;
    margin: 3rem;
    border: 1px solid white;
    background-color: rgba(34, 34, 34, 0.8);
    width: 1000px;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .contact-text-container {
        flex-direction: column;
    }
}

.contact-text-container div:last-child {
    text-align: end;
}

.video-responsive {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
}

@media (max-width: 768px) {
    .video-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

/* Card.css */
.card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 16px;
    cursor: pointer;
}

.card-image-container {
    width: 100%;
    height: 200px; /* Set a fixed height for the image container */
    overflow: hidden; /* Hide overflow to contain the image */
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
}

.card:hover .card-image {
    transform: scale(1.1);
}

.cards {
    width: 400px;
    border: 1px solid #ccc;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin: 16px;
}

.cards-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.cards-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cards-content {
    padding: 16px;
}

.cards-title {
    font-size: 1.25rem;
    margin: 10px 0;
    text-align: center;
}

.cards-description {
    font-size: 1rem;
    margin: 0;
}

.hero-container {
    position: relative;
    width: 100%;
    height: 400px;
}

@media (max-width: 768px) {
    .hero-container {
        max-height: 200px;
    }
}

.hero-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.card-with-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin: 8rem 2rem 4rem 2rem;
}

@media (max-width: 768px) {
    .card-with-image-container {
        flex-direction: column;
        margin: 4rem 1rem;
    }
    .inventory-input {
        padding-top: 2rem;
    }
}

.sell-form-container {
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
}

.sell-form-container > div {
    width: 100%;
    max-width: 40rem;
}

.submitted-form-container {
    border: 1px solid red;
}

.contact-widget {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.select-container {
    position: relative;
    border: 2px solid white;
    font-size: 18px;
    transition: 0.2s;
}

.select-container:hover {
    border: 2px solid rgb(211, 174, 95);
}

.select-container:hover .select-header-toggled {
    border-bottom: 2px solid rgb(211, 174, 95); /* Change border when container is hovered */
}

.select-container p {
    margin: 8px 0;
    padding: 0 0.5rem;
}

.select-label {
    position: absolute;
    top: -0.8rem;
    left: 1rem;
    background-color: #0f1a25;
    padding: 0 0.5rem;
}

.select-icon {
    position: absolute;
    right: 0;
    display: flex;
    padding: 1rem 0.5rem;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.select-header-toggled {
    border-bottom: 2px solid white;
    cursor: pointer;
    padding: 0.5rem;
}

.select-header {
    border-bottom: 0;
    cursor: pointer;
    padding: 0.5rem;
}

.select-options {
    max-height: 200px;
    overflow-y: auto;
    padding: 0.5rem;
}

.select-options p {
    margin: 0;
    cursor: pointer;
}

.select-items {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 0.5rem;
}

.select-items:hover {
    background-color: #314c67;
}

.select-options p:last-child {
    border-bottom: none;
}

.selected {
    color: red;
}

.sell-steps-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 4rem 0;
}

.sell-form-info-after {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0;
}

.sell-form-info-after > div {
    height: 8px;
    width: 8px;
    background-color: rgb(211, 174, 95);
    border-radius: 50%;
}

.sell-form-info-after > p {
    color: rgb(211, 174, 95) !important;
    margin: 0;
}

.divider {
    background: linear-gradient(90deg, #fff 0, #101010);
    height: 0.1rem;
    margin: 1.5rem 0;
}

/* .divider {
    height: 0.1rem;
    background: linear-gradient(90deg, rgb(211, 174, 95) 0%, black 100%);
    background-size: 200% 100%;
    animation: colorChange 2s infinite alternate;
  }
  
  @keyframes colorChange {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 0%;
    }
  } */

.divider-2 {
    background: linear-gradient(90deg, rgb(211, 174, 95) 0, #101010);
    height: 0.2rem;
    margin-top: 3rem;
}

.divider-container {
    margin: 0 10rem;
}

.divider-container-2 {
    display: none;
}

@media (max-width: 768px) {
    .divider-container-2 {
        display: block;
        margin: 4rem;
    }
    .ka-new {
        margin-bottom: 0;
        margin-top: 4rem;
    }
}

.text-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.text-container > div {
    max-width: 80rem;
    padding: 2rem;
}

.text-container > div > div > p > span {
    font-weight: bold;
}

.text-container > div > div > p > a {
    font-weight: bold;
}

/* .text-container > div > div > h3 {
    color: rgb(211, 174, 95);
} */

.fault-report-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 2rem;
}

.fault-report-container > div {
    max-width: 60rem;
}

.standard-btn {
    background-color: transparent;
    color: black;
    border: 2px solid black;
    padding: 0.3rem;
    cursor: pointer;
    border-radius: 25px;
    transition: 0.2s;
    display: flex;
    /* justify-content: space-around; */
    gap: 0.3rem;
    align-items: center;
}

.standard-btn:hover {
    /* background-color: rgb(211, 174, 95);
    border-color: rgb(211, 174, 95); */
    background-color: rgb(0, 0, 0, 0.3);
    border-color: rgb(0, 0, 0, 0.01);
}

.info-btn {
    margin-left: 8px;
    cursor: pointer;
}

.info-btn:hover {
    color: rgb(211, 174, 95);
}

.fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1;
}

.fullscreen-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    max-height: 90%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    height: 100%;
    width: 100%;
}

.fullscreen-wrapper > div > svg {
    cursor: pointer;
}

.fullscreen-wrapper > div > p {
    cursor: pointer;
}

.fullscreen-img-container {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.fullscreen-toggle-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    right: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-toggle-container > p {
    border: 2px solid transparent;
    padding: 0.3rem 0.5rem;
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 8px;
    cursor: pointer;
}

.fullscreen-toggle-container > p:hover {
    border: 2px solid rgb(78, 78, 78);
    background-color: rgb(78, 78, 78);
}

.fullscreen-img-container img {
    width: 100%;
    height: auto;
}

.fullscreen-img-btn-left {
    padding-right: 1rem;
}

.fullscreen-img-btn-right {
    padding-left: 1rem;
}

input::-webkit-calendar-picker-indicator {
    content: '';
    display: block;
    background: url('./assets/calendermedium.png') no-repeat;
    width: 32px;
    height: 30px;
    position: absolute;
    color: rgb(255, 0, 0);
    top: 50%;
    right: 0;
    margin-top: -14px;
}

input::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
}

.calculate-container {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    border-radius: 5px;
}

.infographic-block-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 10rem;
    overflow: hidden;
}

.infographic-block-text {
    max-width: 800px;
    min-width: 500px;
    margin-right: -250px;
    background-color: rgba(48, 48, 47, 0.6);
    border-radius: 4px;
}

.infographic-block-text__right {
    max-width: 800px;
    min-width: 500px;
    margin-left: -250px;
    background-color: rgba(48, 48, 47, 0.6);
    border-radius: 4px;
}

.infographic-block-text > p {
    padding: 0 10px;
}

.infographic-block-text > h1 {
    padding: 0 10px;
    font-size: 3rem;
}

.infographic-block-text__right > p {
    padding: 0 10px;
}

.infographic-block-text__right > h1 {
    padding: 0 10px;
    font-size: 3rem;
}

.infographic-block-img {
    max-width: 700px;
    max-height: 600px;
}

.infographic-block-img img {
    height: auto;
    min-height: 450px;
    min-width: 600px;
    width: 100%;
    z-index: -1;
    position: relative;
    object-fit: cover;
}

.infographic-block-img video {
    height: auto;
    min-height: 450px;
    min-width: 600px;
    width: 100%;
    z-index: -1;
    position: relative;
    object-fit: cover;
}

@media (max-width: 1217px) {
    .infographic-block-container {
        margin: 4rem 4rem;
    }
    .infographic-block-text {
        min-width: 0;
        margin-right: -400px;
    }
    .infographic-block-text__right {
        min-width: 0;
        margin-left: -500px;
    }
}

@media (max-width: 870px) {
    .infographic-block-container {
        margin: 0 2rem;
    }
    .infographic-block-text {
        min-width: 0;
        margin-right: -400px;
    }
    .infographic-block-img {
        overflow: hidden;
    }
    .divider-container {
        margin: 4rem;
    }
}

@media (max-width: 768px) {
    .contact-widget {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    .infographic-block-container {
        margin: 0;
    }
    .divider-container {
        margin: 4rem;
    }
    .infographic-block-container {
        display: flex;
        flex-direction: row;
    }
    .infographic-block-text {
        position: absolute;
        margin-right: 0;
        background-color: transparent;
        border-radius: 0;
        min-width: 0;
        max-width: 600px;
        overflow-x: hidden;
    }
    .infographic-block-text__right {
        position: absolute;
        margin-left: 0;
        margin: 0;
        background-color: transparent;
        border-radius: 0;
        min-width: 0;
        max-width: 600px;
        overflow-x: hidden;
    }
    .infographic-block-img img {
        position: relative;
    }
    .infographic-block-text > h1 {
        padding: 0 10px;
        font-size: 2.5rem;
    }
    .infographic-block-text__right > h1 {
        padding: 0 10px;
        font-size: 2.5rem;
    }
}

@media (max-width: 540px) {
    .infographic-block-text p {
        font-size: 16px;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 4px 10px;
        margin: 0 10px;
    }
    .infographic-block-text__right p {
        font-size: 16px;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 4px 10px;
        margin: 0 10px;
    }
}

@media (max-width: 540px) {
    .infographic-block-text h1 {
        font-size: 2rem;
        margin-left: 10px;
    }
    .infographic-block-text__right h1 {
        font-size: 2rem;
        margin-left: 10px;
    }
}

@media (max-width: 280px) {
    .infographic-block-text p {
        font-size: 13px;
    }
    .infographic-block-text__right p {
        font-size: 13px;
    }
}

.underline-1 {
    position: relative;
}

.underline-1::after {
    content: '';
    width: 100%;
    height: 10px;
    position: absolute;
    background-image: url('./assets/needle-underline.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -10px;
    left: 0;
}

.underline-2 {
    position: relative;
}

.underline-2::after {
    content: '';
    width: 100%;
    height: 30px;
    position: absolute;
    background-image: url('./assets/test.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -23px;
    left: 0;
}

.btn-95,
.btn-95 *,
.btn-95 :after,
.btn-95 :before,
.btn-95:after,
.btn-95:before {
    border: 0 solid;
    box-sizing: border-box;
}
.btn-95 {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: button;
    background-color: #000;
    background-image: none;
    color: #fff;
    cursor: pointer;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 100%;
    font-weight: 900;
    line-height: 1.5;
    margin: 0;
    -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
    text-transform: uppercase;
}
.btn-95:disabled {
    cursor: default;
}
.btn-95:-moz-focusring {
    outline: auto;
}
.btn-95 svg {
    vertical-align: middle;
}
.btn-95 [hidden] {
    display: none;
}
.btn-95 {
    background: rgb(211, 174, 95, 0.6);
    background-clip: content-box;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 60px;
    -webkit-mask-image: none;
    padding: 0;
    position: relative;
    transition: 0.2s;
    width: 60px;
    margin: 1rem;
}
.btn-95:hover {
    background: #fff;
}
.btn-95 svg {
    --size: 30px;
    fill: #fff;
    display: block;
    height: var(--size);
    left: calc(50% - var(--size) / 2);
    position: absolute;
    top: calc(50% - var(--size) / 2);
    width: var(--size);
}
.btn-95:hover svg {
    color: #d3ae5f !important;
}
.btn-95:before {
    border: 5px solid #fff;
    border-radius: 50%;
    content: '';
    inset: 1px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: scale(2);
    transition: 0.2s;
}
.btn-95:hover:before {
    opacity: 1;
    transform: scale(1);
}

@media (max-width: 768px) {
    .btn-95 {
        height: 50px;
        width: 50px;
        margin: 0.5rem;
    }
    .btn-95 svg {
        --size: 25px;
    }
}

.warranty_card {
    border: 1px solid black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 1px 1px 5px white;
    text-align: center;
}

.warranty_card_price p {
    font-size: 32px;
    font-weight: bold;
}

.warranty_card a {
    color: white;
    background-color: rgb(211, 174, 95);
    border-radius: 4px;
    padding: 10px 0;
    border: 1px solid transparent;
    transition: 0.3s;
}

.warranty_card a:hover {
    background-color: rgba(211, 174, 95, 0.5);
    border: 1px solid rgb(211, 174, 95);
    border-radius: 4px;
    color: black;
}

.warranty_card_container {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}

@media (max-width: 768px) {
    .warranty_card_container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}
